div.ss-wrap p
{
    padding: 0 0 0 10px;
}
div.ss-wrap *
{

}

input.ss-input
{
	display: block;
}

input.ss-input-thinking
{
    background: rgba(0,0,0,0.3);
}

ul.ss-box
{
	/* Will be absolutely positioned in the JavaScript */
    margin: 0 !important;
	padding: 0 !important;
    width: 100% !important;
	/*width: 304px;*/
    left: 4%;
	z-index: 999999;
	border: 1px solid #919191;
	font-size: 11px;
	background: #fff;
	-moz-box-shadow: 2px 2px 5px 0 #c5c5c5;
	list-style: none;
    top: 38px !important;
}
	
	ul.ss-box li
	{
		float: left;
		width: 100%;
		clear: both;
	}
	
	ul.ss-box li,
	ul.ss-box li *
	{
		cursor: pointer;
	}

	ul.ss-box li.ss-header
	{
        display: none;
		height: 13px;
		overflow: hidden;
		padding: 5px 0;
		color: #fff;
		/*background: transparent url(../images/jquery-smartsuggest/search-header-bg.gif) repeat-x bottom;*/
        background: #111;
	}

		ul.ss-box li.ss-header p.ss-header-text
		{
			margin: 0 !important;
			padding: 0 0 0 10px !important;
			float: left;
			font-size: 12px;
			font-weight: bold;
		}

		ul.ss-box li.ss-header p.ss-header-limit
		{
			margin: 0 !important;
			padding: 0 10px 0 0 !important;
			float: right;
			font-size: 11px;
			font-weight: normal;
		}

	ul.ss-box li.ss-result
	{
		margin: 0 !important;
		padding: 0 !important;
		border-bottom: 1px solid #c5c5c5;
	}

		ul.ss-box li.ss-result a
		{
			display: block;
			padding: 5px 10px;
			text-decoration: none !important;
			background: #fff;
            border-right: 3px solid #000;
		}
		
		ul.ss-box li.ss-result a:hover
		{
			/*background: #cde0ff url(../images/jquery-smartsuggest/search-item-hover.gif) repeat-x top; /* Also is set in JavaScript file for IE6 compatibility */
            border-right: 5px solid #67ab67;
		}

		ul.ss-box li.ss-result img
		{
			margin-right: 12px;
            width: 50px;
		}

		ul.ss-box li.ss-result p
		{
			margin: 0 !important;
			padding: 0 0 10px !important;
			color: #444;
			font-weight: normal;
			font-size: 10px;
		}

			ul.ss-box li.ss-result p span.ss-result-title
			{
				display: inline !important;
				margin: 0 !important;
				padding: 0 !important;
				font-size: 11px;
				font-weight: bold;
				color: #222;
			}