.pwdwidgetdiv
{
	width:100%;
}

.pwdfield
{
    display:block;
}

.pwdopsdiv
{
	display: block;
	margin-right:6px;	
}
.pwdopsdiv a
{
	font-family : Arial, Helvetica, sans-serif;
	font-size : 10px; 
}

.pwdstrengthbar
{
	float:right;
	background:#cccccc;
	height:4px;
	margin:0;
}

.pwdstrength
{
	float:right; 
	height:20px;
	width:70px;
	margin-top:3px;

}
.pwdstrengthstr
{
    float:right;
	clear:both;
	height:14px;
	margin-top:0px;
	font-family : Arial, Helvetica, sans-serif;
	font-size : 10px; 

}